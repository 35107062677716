/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import * as THREE from 'three'
import { useFrame, useThree } from "@react-three/fiber";


export default function Model({ size, ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/games-packed.glb')
  const { actions } = useAnimations(animations, group)

  const nodesName = Object.keys(nodes)

  useEffect(()=>{
    nodesName.forEach((n)=>{
        if (nodes[n].type === 'SkinnedMesh' ){
            nodes[n].frustumCulled = false
            let geo = nodes[n].geometry
            geo.boundingBox.max = new THREE.Vector3(100, 100, 100)
            geo.boundingBox.min = new THREE.Vector3(-100, -100, -100)
            geo.boundingSphere.center = new THREE.Vector3(0, 0, 0)
        }
    })

  const playNormal = [
    'EW Board AST Action.001', 'EW Board WHM Action.001', 'EW Board WAR Action.001',
    'EW Board SAM Action.001', 'EW Board RDM Action.001', 'EW Board  NIN Action.001',
    'EW Board GNB Action.001', 'EW Board DNC Action.001', 'EW Board Boss Action.001',
    'Torb Body Action.001', 'Torb Top Action.001', 'Torb Flap Action 2.001', 
    'Torb Flap Action.001', 'Torb Nozzle Action 2.001', 'Torb Nozzle Action.001'
  ]

  const playSlow = [
    'Key.009Action.003', 'EW Aetheryte Body Spin.001','Aetheryte Body Spin.001',
  ]

  const playRandomSpeed = [
    'GOT FLOAT ACTION', 'HELMET FLOAT ACTION','PERSONA FLOAT ACTION','EW Aetheryte Spiral Spin.001',
     'EW Aetheryte Outer Body Spin 2.001', 'EW Aetheryte Outer Body Spin.001',
    'EW Aetheryte Hoop Spin.001', 'Aetheryte Small Crystal Spin.001', 
    'Cylinder.024Action.003', 'Aetheryte Small Crystal Action 2.001','Aetheryte Small Crystal Action.001'
  ]
  playNormal.forEach((a)=>{
    actions[a].play()   
  })

  playSlow.forEach((a)=>{
    actions[a].play().timeScale = 0.5
  })

  playRandomSpeed.forEach((a)=>{
    actions[a].play().timeScale = Math.random() * (0.8 - 0.5 + 1) + 0.5
  })

  materials['04 AETHERYTE CRYSTAL MATERIALS.'].emissiveIntensity = 20
  materials['04 AETHERYTE CRYSTAL MATERIALS.'].emissive = new THREE.Color(18/255, 105/255, 135/255)
  // materials['04 AETHERYTE CRYSTAL MATERIALS.'].emissiveMap = null
  materials['Persona Emission'].emissive = new THREE.Color("rgb(163, 43, 36)")
  materials['Persona Emission'].emissiveIntensity = 5
  materials['Persona Emission'].color = new THREE.Color("rgb(163, 43, 36)")

},)

//TORB FOLLOW MOUSE

const { viewport } = useThree()
const torbRef = useRef()

useFrame((state) => {
  

    const x = (state.mouse.x * viewport.width) / 2
    const y = (state.mouse.y * viewport.height) / 2

    nodes['Bone001'].lookAt(new THREE.Vector3(-10-x, 5-y, -10))
  

})

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="GHOST" position={[-3.22134757, 2.77620101, -3.30751562]} rotation={[0, 1.11606135, 0]} scale={[1.45300305, 1.45300293, 1.45300305]}>
          <mesh name="mesh_0"   geometry={nodes.mesh_0.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="04-3-2_HELMET002" position={[-3.32291341, 2.601089, -2.18714619]} rotation={[-0.01485491, 0.00439261, 0.07016738]} scale={[1.34157026, 1.34157038, 1.34157014]}>
          <mesh name="mesh_1"   geometry={nodes.mesh_1.geometry} material={materials['04 HELMET TORB PERS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="04-3-1_PERSONA002" position={[-3.31978989, 2.78263378, -1.11265779]} rotation={[0, -0.08873971, 0]} scale={1.33129954}>
          <group name="Persona_Flame" position={[0.15526629, -0.19269323, 0.10775644]} rotation={[0, 0, -0.11764434]}>
            <mesh name="mesh_2"   geometry={nodes.mesh_2.geometry} material={materials['Persona Emission']} morphTargetDictionary={nodes.mesh_2.morphTargetDictionary} morphTargetInfluences={nodes.mesh_2.morphTargetInfluences} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="Plane008" position={[0, 0.03004408, 0.05306673]} rotation={[2.03707627, 0, 0]}>
            <mesh name="mesh_3"   geometry={nodes.mesh_3.geometry} material={materials['04 HELMET TORB PERS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <mesh name="mesh_4"   geometry={nodes.mesh_4.geometry} material={materials['04 HELMET TORB PERS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="Torb003" position={[-3.33730149, 4.09609699, -3.34345961]} rotation={[-Math.PI, -0.09454572, -Math.PI]} scale={[1.26986599, 1.26986563, 1.26986599]}>
          <primitive object={nodes.Bone} />
          <group name="Base_Bottom003">
            <group position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668}>
              <skinnedMesh name="mesh_5" geometry={nodes.mesh_5.geometry} material={materials['04 HELMET TORB PERS']} skeleton={nodes.mesh_5.skeleton} />
              {/* <skinnedMesh name="mesh_5_1" geometry={nodes.mesh_5_1.geometry} material={materials['Black Metal']} skeleton={nodes.mesh_5_1.skeleton} /> */}
            </group>
          </group>
          <group name="Turret-Top-2003">
            <group name="Turret_Top003">
              <group name="Cylinder035">
                <skinnedMesh name="mesh_6" geometry={nodes.mesh_6.geometry} material={materials['04 HELMET TORB PERS']} skeleton={nodes.mesh_6.skeleton} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
              </group>
              <group name="Cylinder036" />
              <group name="Plane012" />
              <group name="Plane019" />
              <group name="Turret_Top006" />
              <skinnedMesh ref={torbRef} name="mesh_7" geometry={nodes.mesh_7.geometry} material={materials['04 HELMET TORB PERS']} skeleton={nodes.mesh_7.skeleton} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
            </group>
          </group>
          <primitive object={nodes.FootC} />
          <primitive object={nodes.FootD} />
          <primitive object={nodes.Foot_A} />
          <primitive object={nodes.FootB} />
        </group>
        <group name="04-2-3_SPIRAL002" position={[-3.09608412, 1.05564487, -3.05106044]} scale={1.34214079}>
          <mesh name="mesh_8"   geometry={nodes.mesh_8.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="04-2-3_BODY002" position={[-3.1100955, 1.66412151, -3.0833261]} scale={[1.34214115, 1.34214079, 1.34214115]}>
          <group name="gold_spiral_1001" position={[0.02844095, -0.17369664, 0.04551888]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="mesh_9"   geometry={nodes.mesh_9.geometry} material={materials['04 AETHERYTE MATERIALS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <mesh name="mesh_10"   geometry={nodes.mesh_10.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="04-2-3_EW_OUTER002" position={[-3.09045959, 1.65020418, -3.08657575]} rotation={[2e-8, -0.9776431, -0.70871818]} scale={[1.34214091, 1.34214079, 1.34214079]}>
          <group name="Circle011">
            <mesh name="mesh_12"   geometry={nodes.mesh_12.geometry} material={materials['04 AETHERYTE MATERIALS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <mesh name="mesh_13"   geometry={nodes.mesh_13.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="04-2-3_EW_AETHER002" position={[-3.08689141, 1.65668857, -3.08319116]} rotation={[0, 0, -0.51558414]} scale={[1.34214079, 1.34214067, 1.34214115]}>
          <group name="Circle007" position={[0.30611253, 0.00867915, 0.00350356]} rotation={[Math.PI / 2, 0.51558414, -1.9e-7]}>
            <mesh name="mesh_14"   geometry={nodes.mesh_14.geometry} material={materials['04 AETHERYTE MATERIALS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="Circle010" position={[0, 0, 0.03455949]} rotation={[Math.PI / 2, 0, 0]}>
            <mesh name="mesh_15"   geometry={nodes.mesh_15.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <mesh name="mesh_16"   geometry={nodes.mesh_16.geometry} material={materials['04 AETHERYTE MATERIALS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="04-2-3_HOOP002" position={[-3.0912993, 1.12800586, -3.08477736]} rotation={[0, 0, 0.22959407]} scale={[1.34214067, 1.34214103, 1.34214079]}>
          <mesh name="mesh_17"   geometry={nodes.mesh_17.geometry} material={materials['04 AETHERYTE MATERIALS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group name="Empty002" position={[-3.1367228, 1.29739463, -1.73787737]} rotation={[Math.PI / 2, 1.00287501, -Math.PI / 2]} scale={1.38497257}>
          <group name="04-2-2_AST002" position={[-0.19948447, -0.0100565, 0.23227572]} rotation={[0, -0.16353188, 0]} scale={[0.59094894, 0.59094924, 0.59094924]}>
            <mesh name="mesh_18"   geometry={nodes.mesh_18.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_BOSS002" position={[-0.07814002, -0.00424826, -0.24813747]}>
            <mesh name="mesh_19"   geometry={nodes.mesh_19.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_DNC003" position={[0.17488337, -0.01282072, 0.16816044]} rotation={[0, 0.48582946, 0]} scale={0.5909493}>
            <mesh name="mesh_20"   geometry={nodes.mesh_20.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_GNB002" position={[-0.07482755, -0.01402199, 0.29158223]} scale={[0.59094977, 0.59094983, 0.59094983]}>
            <mesh name="mesh_22"   geometry={nodes.mesh_22.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_NIN002" position={[0.05080414, -0.01483035, 0.33817112]} rotation={[0, 0.14409848, 0]} scale={[0.59094971, 0.59094983, 0.59094977]}>
            <mesh name="mesh_23"   geometry={nodes.mesh_23.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_RDM002" position={[-0.31921124, -0.01402199, 0.1918397]} rotation={[0, -0.40931102, 0]} scale={[0.59094912, 0.59094983, 0.59094965]}>
            <mesh name="mesh_24"   geometry={nodes.mesh_24.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_SAM002" position={[-0.19658768, -0.01402199, 0.35340452]} rotation={[0, -0.13385961, 0]} scale={[0.59094971, 0.59094983, 0.59094977]}>
            <mesh name="mesh_25"   geometry={nodes.mesh_25.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_WAR002" position={[-0.07644808, -0.01402199, 0.17216158]} rotation={[Math.PI, -3.3e-7, Math.PI]} scale={[0.59094977, 0.59094983, 0.59094983]}>
            <mesh name="mesh_26"   geometry={nodes.mesh_26.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group name="04-2-2_WHM002" position={[0.04815996, -0.01333809, 0.22001469]} rotation={[0, 0.18932075, 0]} scale={[0.59094888, 0.59094924, 0.59094924]}>
            <mesh name="mesh_27"   geometry={nodes.mesh_27.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
        </group>
        <group name="04-2-1_EMPTY002" position={[-3.01637626, 0.9967277, -0.20965208]} scale={[0.29013345, 0.29013342, 0.29013345]}>
          <group name="Cylinder032" position={[1.14633083, 1.07130003, -0.10679382]}>
            <group position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668}>
              <mesh name="mesh_28"   geometry={nodes.mesh_28.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} />
              <mesh name="mesh_28_1"   geometry={nodes.mesh_28_1.geometry} material={materials['04 AETHERYTE MATERIALS']} />
            </group>
          </group>
          <group name="Cylinder033" position={[-0.9501133, 1.04891872, 0.54053485]} rotation={[Math.PI, -0.41012733, Math.PI]}>
            <group position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668}>
              <mesh name="mesh_29"   geometry={nodes.mesh_29.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} />
              <mesh name="mesh_29_1"   geometry={nodes.mesh_29_1.geometry} material={materials['04 AETHERYTE MATERIALS']} />
            </group>
          </group>
        </group>
        <group name="04-2-1_Aetehryte_Body002" position={[-3.01637626, 0.9967277, -0.20965208]} scale={[1.43003392, 1.4300338, 1.43003392]}>
          <group name="Cylinder026" position={[0, 0.14926851, 0]}>
            <mesh name="mesh_30"   geometry={nodes.mesh_30.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
          </group>
          <group position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668}>
            <mesh name="mesh_31"   geometry={nodes.mesh_31.geometry} material={materials['04 AETHERYTE MATERIALS']} />
            <mesh name="mesh_31_1"   geometry={nodes.mesh_31_1.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} />
          </group>
        </group>
        <group name="Aetheryte_Hoop_Spin001" position={[-3.01637626, 1.52765334, -0.20965208]} rotation={[-2.66632915, -3.2e-7, -Math.PI]} scale={[1.4300338, 1.43003392, 1.43003392]}>
          <group name="Cylinder028" position={[0, 0.00004916, -0.43149284]} rotation={[Math.PI / 2, 0, 0]} scale={[0.20288573, 0.2028857, 0.20288575]}>
            <group position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668}>
              <mesh name="mesh_32"   geometry={nodes.mesh_32.geometry} material={materials['04 AETHERYTE MATERIALS']} />
              <mesh name="mesh_32_1"   geometry={nodes.mesh_32_1.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} />
            </group>
          </group>
          <mesh name="mesh_33"   geometry={nodes.mesh_33.geometry} material={materials['04 AETHERYTE MATERIALS']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
        </group>
        <group position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668}>
          <mesh name="mesh_11"   geometry={nodes.mesh_11.geometry} material={materials['04 AETHERYTE MATERIALS']} />
          <mesh name="mesh_11_1"   geometry={nodes.mesh_11_1.geometry} material={materials['04 AETHERYTE CRYSTAL MATERIALS.']} />
        </group>
        <mesh name="mesh_21"   geometry={nodes.mesh_21.geometry} material={materials['04 EW BOARD AND GHOST']} position={[-3.76806307, -0.51828688, -3.75982237]} scale={0.00036668} />
      </group>
    </group>
  )
}

useGLTF.preload('/models/games-packed.glb')
