/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations, useScroll} from '@react-three/drei'
import { useFrame } from "@react-three/fiber";
import * as THREE from 'three'


export default function Model({ getBreak, ...props }) {
  const group = useRef()

  const { nodes, materials, animations } = useGLTF('/models/dancing-packed.glb')
  const { actions } = useAnimations(animations, group)
  const scroll = useScroll()
  useEffect(()=>{
    animations.forEach((a)=>{
      actions[a.name].play()
    })
    group.current.scale.set(0, 0, 0)
  })

  useFrame(()=>{
    const section = getBreak(scroll.offset)
    group.current.scale.lerp( section === 4 ?  new THREE.Vector3(1, 1, 1) : new THREE.Vector3(0.0, 0.0, 0.0), 1)
    
  }, [])


  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="DANCING_MAN" position={[0.61412907, 0.02507089, 0]} rotation={[Math.PI / 2, -2e-8, -0.68558167]} scale={0.00569359}>
          <primitive object={nodes.mixamorigHips} />
          <group name="CharacterModel007">
            <skinnedMesh  name="mesh_0" geometry={nodes.mesh_0.geometry} material={materials.CHARACTER} skeleton={nodes.mesh_0.skeleton} position={[-0.60842246, 0.01723782, -0.95986861]} scale={0.00022945} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/dancing-packed.glb')
