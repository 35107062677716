/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/geo-packed.glb')
  const { actions } = useAnimations(animations, group)

  const playRandom = ['Geo Satellite Action.001', 'Moon Action']
  const playSlow = ['Geo Background Action.001', 'Geo Mountains Action.001']
  useEffect(()=>{
  
    playRandom.forEach((a)=>{
      actions[a].play().timeScale = Math.random() * (0.8 - 0.3 + 1) + 0.3
    })

    playSlow.forEach((a)=>{
      actions[a].play().timeScale = 0.5
    })

  })

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="SATELLITE001" position={[-1.07701814, 4.11866617, -4.47087669]} rotation={[3.10792446, 0.21547712, -2.36139583]} scale={0.1732502}>
          <group name="Cone003" position={[0.0000515, -0.59542942, 0.00025558]} rotation={[-Math.PI, 0.02521321, -1e-8]} scale={[1, 0.73858434, 1]}>
            <mesh name="mesh_0"   geometry={nodes.mesh_0.geometry} material={materials['02 GEO MATERIALS']} position={[-5.07838726, -1, -1.02056181]} scale={0.00044646} />
          </group>
          <group name="Cylinder010" position={[0.00053215, -1.13449764, 0.00353432]} rotation={[0, 0.21805186, 0]} scale={[0.07891273, 0.07891274, 0.07891273]}>
            <mesh name="mesh_1"   geometry={nodes.mesh_1.geometry} material={materials['02 GEO MATERIALS']} position={[-5.07838726, -1, -1.02056181]} scale={0.00044646} />
          </group>
          <group name="Plane004" position={[1.41362953, 1.56429863, -0.11798859]} rotation={[1.63079846, -0.01328621, 0.02533704]}>
            <mesh name="mesh_2"   geometry={nodes.mesh_2.geometry} material={materials['02 GEO MATERIALS']} position={[-5.07838726, -1, -1.02056181]} scale={0.00044646} />
          </group>
          <mesh name="mesh_3"   geometry={nodes.mesh_3.geometry} material={materials['02 GEO MATERIALS']} position={[-5.07838726, -1, -1.02056181]} scale={0.00044646} />
        </group>
        <group name="Mountains001" position={[-0.9544943, 2.00086975, -4.60252523]} scale={[1.26899421, 0.78609359, 0.24439211]}>
          <mesh name="mesh_4"   geometry={nodes.mesh_4.geometry} material={materials['02 GEO MATERIALS']} morphTargetDictionary={nodes.mesh_4.morphTargetDictionary} morphTargetInfluences={nodes.mesh_4.morphTargetInfluences} position={[-5.07838726, -1, -1.02056181]} scale={0.00044646} />
        </group>
        <group name="Plane005" position={[-1.68799675, 3.19108438, -5.18015909]} rotation={[Math.PI / 2, 0, 0]} scale={1.64530396}>
          <mesh name="mesh_5"   geometry={nodes.mesh_5.geometry} material={materials['02 GEO MATERIALS']} position={[-5.07838726, -1, -1.02056181]} scale={0.00044646} />
        </group>
        <group name="Moon" position={[-2.00112009, 3.1243999, -4.63379908]} rotation={[-0.11792736, 0.33346523, -3.10283224]} scale={0.38525558}>
          <mesh name="mesh_6"   geometry={nodes.mesh_6.geometry} material={materials['Material.003']} position={[-5.07838726, -1, -1.02056181]} scale={0.00044646} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/geo-packed.glb')
