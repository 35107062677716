/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState} from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three';

export default function Model({ size, ...props }) {
  const group = useRef()

  const { nodes, materials } = useGLTF('/models/aboutme-packed.glb')

  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = size === 's' || size === 'm' ? "/textures/videoPortFolio-lowreser.mp4" : "/textures/videoPortFolio-lowres.mp4";
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.playsInline = true;
    vid.play();
    return vid;
  });

  const [video2] = useState(() => {
    const vid = document.createElement("video");
    vid.src = size === 's' || size === 'm' ? "/textures/videoPortFolio2-lowreser.mp4" : "/textures/videoPortFolio2-lowres.mp4";
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.playsInline = true;
    vid.play();
    
    return vid;
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.04688025, 0.0319801, -4.46815252]} scale={0.00021267}>
        <mesh geometry={nodes.mesh_0.geometry} material={materials['01 DESK OBJECTS MATERIAL']} />
        <mesh geometry={nodes.mesh_0_1.geometry} material={materials['01 COMPUTER SCREEN']} />
        <mesh geometry={nodes.mesh_0_2.geometry} material={materials['01 MOUSEPAD EMISSION']} />
        <mesh geometry={nodes.mesh_0_3.geometry} material={materials['0X_Steel_Scratched_mqm']} />
      </group>
      <mesh position={[1.09, 2.56, -3.845]} rotation={[-0.05, 0.085, 0.005]} scale={0.79}>
        <planeBufferGeometry args={[1.976, 1.164]}/>
          <meshStandardMaterial color={'#fff'} toneMapped={true}>
              <videoTexture attach="map" args={[video]} emissiveIntensity={1}  encoding={THREE.sRGBEncoding}/> 
              <videoTexture attach="emissiveMap" args={[video]}/> 
          </meshStandardMaterial>
      </mesh>
      <mesh position={[2.62, 2.08, -3.99]} rotation={[0, -0.2, 0]} scale={1.35}>
        <planeBufferGeometry args={[0.980, 0.559]}/>
          <meshStandardMaterial color={'#fff'} toneMapped={true}>
            <videoTexture attach="map" args={[video2]} emissiveIntensity={1}  encoding={THREE.sRGBEncoding}/> 
            <videoTexture attach="emissiveMap" args={[video2]}/> 
          </meshStandardMaterial>
      </mesh>
    </group>
  )
}

useGLTF.preload('/models/aboutme-packed.glb')
